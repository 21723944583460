import axios from "axios"
import { apiUrl } from "../enumerations/constants"

export const createCashFlowIn = async (token, payload) => {
  const { data: response } = await axios.post(`${apiUrl}/cash_flow_in`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const updateCashFlowIn = async (token, payload) => {
  const { data: response } = await axios.put(`${apiUrl}/cash_flow_in`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const deleteCashFlowIn = async (token, id) => {
  const { data: response } = await axios.delete(`${apiUrl}/cash_flow_in/${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getCashFlowIns = async (token, query) => {
  const { data: response } = await axios.get(`${apiUrl}/cash_flow_ins?${query}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getCashFlowIn = async (token, id) => {
  const { data: response } = await axios.get(`${apiUrl}/cash_flow_in?id=${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}