import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as dealApis from "../../utilities/apis/deal"
import Layout from "../../components/Layout"
import { url } from "../../utilities/enumerations/constants" 

const Categories = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const productState = useSelector(state => state.product)
  const params = new URLSearchParams(window.location.search)
  const thumbnailRef = useRef(null)
  const thumbnailFileRef = useRef(null)
  const thumbnail64Ref = useRef(null)
  const titleRef = useRef(null)
  const slugRef = useRef(null)
  const descriptionRef = useRef(null)
  const costRef = useRef(null)
  const priceRef = useRef(null)
  const discountRef = useRef(null)
  const saleRef = useRef(null)
  const videoRef = useRef(null)
  const whatsappRef = useRef(null)
  const [discount, setDiscount] = useState(0)
  const [items, setItems] = useState([])
  const [isHomePage, setIsHomePage] = useState(false)
  const [selectedOption, setSelectedOption] = useState("")

  const options = productState.products.map((product) => (
    <option key={product.id} value={product.id}>{product.title}</option>
  ))

  const setImage = (type, base64) => {
    switch (type) {
      case "thumbnail":
        thumbnailRef.current.src = base64
        thumbnail64Ref.current.value = base64
        break
    }
  }

  const handleChange = async (e) => {
    try {
      if (e.target.files && e.target.files[0]) {
        if (e.target.files[0].size > 1024000) throw new Error("Image size must be maximum of 1MB")
        if (!["image/jpeg", "image/jpg", "image/png"].includes(e.target.files[0].type)) throw new Error("image must be in format of .jpg, .jpeg or .png")

        const reader = new FileReader()
        const type = e.target.id

        reader.addEventListener("load", function (e) {
          setImage(type, e.target.result)
        })

        reader.readAsDataURL(e.target.files[0])
      }
    } catch (error) {
      alert(error.message)
    }
  }

  const handleClear = (type) => {
    switch (type) {
      case "thumbnail":
        thumbnailRef.current.src = "/upload.jpg"
        thumbnailFileRef.current.value = ""
        thumbnail64Ref.current.value = ""
        break
    }
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      if (items.length < 1) throw new Error("Please add atleast 1 product")

      const formData = new FormData()

      if (e.target.thumbnail.files[0]) formData.append("deal[thumbnail_url]", e.target.thumbnail.files[0])
      else formData.append("deal[thumbnail_url", thumbnail64Ref.current.value)

      formData.append("deal[title]", titleRef.current ? titleRef.current.value : '')
      formData.append("deal[slug]", slugRef.current ? slugRef.current.value : '')
      formData.append("deal[description]", descriptionRef.current ? descriptionRef.current.value : '')
      formData.append("deal[cost]", costRef.current ? costRef.current.value : '')
      formData.append("deal[price]", priceRef.current ? priceRef.current.value : '')
      formData.append("deal[discount]", discountRef.current ? discountRef.current.value : '')
      formData.append("deal[whatsapp_url]", whatsappRef.current ? whatsappRef.current.value : '')
      formData.append("deal[video_url]", videoRef.current ? videoRef.current.value : '')
      formData.append("deal[home_page]", isHomePage)
      items.forEach((item, index) => {
        formData.append(`product_deals[${index}][quantity]`, item.order_quantity)
        formData.append(`product_deals[${index}][product_id]`, item.id)
      })

      if (!params.has("id")) {
        const response = await dealApis.createDeal(token, formData)
        if (!response.status) throw new Error(response.message)

        titleRef.current.value = ""
        slugRef.current.value = ""
        descriptionRef.current.value = ""
        costRef.current.value = ""
        priceRef.current.value = ""
        discountRef.current.value = ""
        saleRef.current.value = ""
        whatsappRef.current.value = ""
        videoRef.current.value = ""
        handleClear("thumbnail")
        setItems([])
        setDiscount(0)
      } else {
        formData.append("deal[id]", parseInt(params.get("id")))
        const response = await dealApis.updateDeal(token, formData)
        if (!response.status) throw new Error(response.message)
      }

      alert("Deal saved")
    } catch (error) {
      alert(error.message)
    }
  }

  const handleItem = () => {
    const product = productState.products[0]
    const item = {
      id: product.id,
      title: product.title,
      description: product.description,
      cost: product.cost,
      price: product.price,
      quantity: product.quantity,
      category_id: product.category_id,
      user_id: product.user_id,
      order_quantity: product.quantity
    }
    setItems(previous => [...previous, item])
  }

  const handleItemDelete = (index) => {
    setItems(previous => {
      previous.splice(index, 1)
      return [...previous]
    })
  }

  const handleItemChange = (e, index, type) => {
    switch (type) {
      case "id":
        setItems(previous => {
          const product = productState.products.find(product => product.id === parseInt(e.target.value))
          const item = {
            id: product.id,
            title: product.title,
            description: product.description,
            cost: product.cost,
            price: product.price,
            quantity: product.quantity,
            category_id: product.category_id,
            user_id: product.user_id,
            order_quantity: previous[index].order_quantity
          }
          previous[index] = item
          return [...previous]
        })
        break
      case "quantity":
        setItems(previous => {
          previous[index].order_quantity = parseInt(e.target.value)
          return [...previous]
        })
        break
    }
  }

  const getDeal = async () => {
    try {
      const response = await dealApis.getDeal(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      setIsHomePage(response.deal.home_page)
      titleRef.current.value = response.deal.title
      slugRef.current.value = response.deal.slug
      descriptionRef.current.value = response.deal.description
      costRef.current.value = response.deal.cost
      priceRef.current.value = response.deal.price
      discountRef.current.value = response.deal.discount
      saleRef.current.value = response.deal.sale
      setSelectedOption(response.deal.primary)
      videoRef.current.value = response.deal.video_url
      whatsappRef.current.value = response.deal.whatsapp_url

      thumbnailRef.current.src = response.deal.thumbnail_url?.replace("public", url)
      thumbnail64Ref.current.value = response.deal.thumbnail_url

      setItems(response.deal.products)
      setDiscount(response.deal.discount)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    if (params.has("id"))
      getDeal()
  }, [])

  if (costRef && costRef.current)
    costRef.current.value = items.reduce((previous, current) => previous += current.cost * current.order_quantity, 0)
  if (priceRef && priceRef.current) {
    priceRef.current.value = items.reduce((previous, current) => previous += current.price * current.order_quantity, 0)
    saleRef.current.value = items.reduce((previous, current) => previous += current.price * current.order_quantity, 0) - discount
  }

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="category" className="my-4">
        <div className="container">
          <form method="POST" onSubmit={handleSubmit}>
            <div className="card">
              <div className="card-header bg-white border-bottom-0 text-center">
                <h3 className="fw-bold m-0">Deal</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-2">
                    <div className="card position-relative">
                      <a className="reset text-danger" onClick={() => handleClear("thumbnail")}><i className="fas fa-times"></i></a>
                      <div className="card-body p-0">
                        <input ref={thumbnail64Ref} type="hidden" />
                        <input ref={thumbnailFileRef} type="file" id="thumbnail" className="form-control d-none" accept=".jpg, .jpeg, .png" onChange={handleChange} />
                        <img ref={thumbnailRef} src="/upload.jpg" className="rounded" onClick={() => thumbnailFileRef.current.click()} />
                      </div>
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="col-3">
                      <div className="form-check mb-3">
                        <input className="form-check-input" type="checkbox" id="homePage" checked={isHomePage} onChange={() => setIsHomePage(!isHomePage)} />
                        <label className="form-check-label" htmlFor="homePage">Display on Home Page</label>
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input ref={titleRef} type="text" className="form-control" id="title" placeholder="Title" required />
                          <label htmlFor="title">Title</label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input ref={slugRef} type="text" className="form-control" id="slug" placeholder="Slug" required />
                          <label htmlFor="slug">Slug</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={descriptionRef} type="text" className="form-control" id="description" placeholder="Description" required />
                      <label htmlFor="description">Description</label>
                    </div>

                    <div className="row g-3">
                      <div className="col-3">
                        <div className="form-floating mb-3">
                          <input ref={costRef} type="number" className="form-control" id="cost" placeholder="Cost" required disabled />
                          <label htmlFor="cost">Cost</label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-floating mb-3">
                          <input ref={priceRef} type="number" className="form-control" id="price" placeholder="Price" required />
                          <label htmlFor="price">Price</label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-floating mb-3">
                          <input ref={discountRef} type="number" className="form-control" id="discount" placeholder="Discount" required onKeyUp={(e) => setDiscount(parseFloat(e.target.value))} />
                          <label htmlFor="discount">Discount</label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-floating mb-3">
                          <input ref={saleRef} type="number" className="form-control" id="sale" placeholder="Sale" required disabled />
                          <label htmlFor="sale">Sale</label>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input ref={whatsappRef} type="text" className="form-control" id="whatsapp" placeholder="Whatsapp Url" />
                          <label htmlFor="whatsapp_url">Whatsapp Url</label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input ref={videoRef} type="text" className="form-control" id="video_url" placeholder="Video Url" />
                          <label htmlFor="video_url">Video Url</label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <table className="table table-bordered shadow-none">
                  <thead className="table-light">
                    <tr>
                      <th scope="col" style={{ width: "30%" }} className="align-middle p-1">Product</th>
                      <th scope="col" style={{ width: "20%" }} className="align-middle p-1">Quantity</th>
                      <th scope="col" style={{ width: "20%" }} className="align-middle p-1 text-center">Cost</th>
                      <th scope="col" style={{ width: "20%" }} className="align-middle p-1 text-center">Price</th>
                      <th scope="col" style={{ width: "10%" }} className="align-middle p-1 text-center">
                        <button type="button" className="btn btn-primary" onClick={handleItem}><i className="bi bi-plus-lg"></i></button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => (
                      <tr key={index.toString()}>
                        <td className="align-middle p-1"><select className="form-select w-100" defaultValue={item.id} onChange={(e) => handleItemChange(e, index, "id")}>{options}</select></td>
                        <td className="align-middle p-1"><input type="number" className="form-control" defaultValue={item.order_quantity} onChange={(e) => handleItemChange(e, index, "quantity")} onKeyUp={(e) => handleItemChange(e, index, "quantity")} /></td>
                        <td className="align-middle p-1 text-center">{(item.cost * item.order_quantity).toFixed(2)}</td>
                        <td className="align-middle p-1 text-center">{(item.price * item.order_quantity).toFixed(2)}</td>
                        <td className="align-middle p-1 text-center">
                          <button type="button" className="btn btn-danger" onClick={() => handleItemDelete(index)}><i className="bi bi-x-lg"></i></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center">
                  <button type="submit" className="btn btn-primary py-3 w-25">Save</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </Layout>
  )
}

export default Categories
