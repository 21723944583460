import axios from "axios"
import { apiUrl } from "../enumerations/constants"

export const createCashFlowOut = async (token, payload) => {
  const { data: response } = await axios.post(`${apiUrl}/cash_flow_out`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const updateCashFlowOut = async (token, payload) => {
  const { data: response } = await axios.put(`${apiUrl}/cash_flow_out`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const deleteCashFlowOut = async (token, id) => {
  const { data: response } = await axios.delete(`${apiUrl}/cash_flow_out/${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getCashFlowOuts = async (token, query) => {
  const { data: response } = await axios.get(`${apiUrl}/cash_flow_outs?${query}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getCashFlowOut = async (token, id) => {
  const { data: response } = await axios.get(`${apiUrl}/cash_flow_out?id=${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}