import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as cashFlowOutApis from "../../utilities/apis/cashFlowOut"
import Layout from "../../components/Layout"

const CashFlowOut = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const typeRef = useRef(null)
  const commentRef = useRef(null)
  const amountRef = useRef(null)
  const dateRef = useRef(null)
  const entryDateRef = useRef(null)
  const params = new URLSearchParams(window.location.search)

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const type = e.target.type.value || null
      const comment = e.target.comment.value
      const amount = e.target.amount.value
      const date = e.target.date.value || null
      const entryDate = e.target.entryDate.value || null
      const payload = { type, comment, amount, date, entry_date: entryDate }

      if (!params.has("id")) {
        const response = await cashFlowOutApis.createCashFlowOut(token, payload)
        if (!response.status) throw new Error(response.message)

        typeRef.current.value = "Own"
        commentRef.current.value = ""
        amountRef.current.value = ""
        dateRef.current.value = ""
        entryDateRef.current.value = ""
      } else {
        payload.id = parseInt(params.get("id"))
        const response = await cashFlowOutApis.updateCashFlowOut(token, payload)
        if (!response.status) throw new Error(response.message)
      }

      alert("Cash Flow Out saved")
    } catch (error) {
      alert(error.message)
    }
  }

  const getCashFlowOut = async () => {
    try {
      const response = await cashFlowOutApis.getCashFlowOut(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      typeRef.current.value = response.cash_flow_out.type
      if (response.cash_flow_out.comment) commentRef.current.value = response.cash_flow_out.comment
      amountRef.current.value = response.cash_flow_out.amount
      dateRef.current.value = response.cash_flow_out.date
      entryDateRef.current.value = response.cash_flow_out.entryDate
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    if (params.has("id"))
      getCashFlowOut()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customer" className="my-4">
        <div className="container">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <div className="card">
                <div className="card-header bg-white border-bottom-0 text-center">
                  <h3 className="fw-bold m-0">Cash Flow Out</h3>
                </div>
                <div className="card-body">
                  <form method="POST" onSubmit={handleSubmit}>
                    <div className="form-floating mb-3">
                      <select ref={typeRef} className="form-select" id="type" required>
                        {['Own', 'Salary', 'Packing Bags', 'Labels', 'Bottles', 'Chemical', 'Miscellaneous', 'Labor'].map((type) => (
                          <option key={type} value={type}>{type}</option>
                        ))}
                      </select>
                      <label htmlFor="type">Select Type</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={commentRef} type="text" className="form-control" id="comment" placeholder="Comment" />
                      <label htmlFor="comment">Comment</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={amountRef} type="number" className="form-control" id="amount" placeholder="Amount" step="0.01" required />
                      <label htmlFor="amount">Amount</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={dateRef} type="datetime-local" className="form-control" id="date" placeholder="Date" required />
                      <label htmlFor="date">Date</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={entryDateRef} type="datetime-local" className="form-control" id="entryDate" placeholder="Entry Date" required />
                      <label htmlFor="entry-date">Entry Date</label>
                    </div>
                    <div className="d-grid">
                      <button type="submit" className="btn btn-primary py-3">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-4"></div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default CashFlowOut
