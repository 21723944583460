import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as orderApis from "../../utilities/apis/order"
import * as dispatcherServices from "../../utilities/services/dispatcher"
import { getProcessedStatus, getOrderStatus, getPaymentStatus, isDeal } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"
import Pagination from "../../components/Pagination"

const Orders = () => {
  const navigate = useNavigate()
  const { token, user, permissions } = useSelector(state => state.user)
  const formRef = useRef(null)
  const [orders, setOrders] = useState([])
  const [rows, setRows] = useState([])
  const [count, setCount] = useState(0)
  const [selected, setSelected] = useState([])
  const [active, setActive] = useState(1)
  const [filters, setFilters] = useState({
    offset: 0,
    limit: 10,
  })

  const handleView = (id) => {
    navigate(`/dashboard/order?id=${id}`)
  }

  const handleSearch = () => {
    const where = {}
    const orderStatus = []
    const paymentStatus = []

    if (formRef.current.from_date.value && formRef.current.from_time.value && formRef.current.to_date.value && formRef.current.to_time.value) {
      where.from_order_datetime = moment(`${formRef.current.from_date.value} ${formRef.current.from_time.value}`).format("YYYY-MM-DD HH:mm")
      where.to_order_datetime = moment(`${formRef.current.to_date.value} ${formRef.current.to_time.value}`).format("YYYY-MM-DD HH:mm")
    } else {
      if (formRef.current.from_date.value && formRef.current.to_date.value) {
        where.from_order_date = moment(formRef.current.from_date.value).format("YYYY-MM-DD")
        where.to_order_date = moment(formRef.current.to_date.value).format("YYYY-MM-DD")
      }
    }

    if (formRef.current.id.value) where.id = formRef.current.id.value.replace(/p/gi, "")
    if (formRef.current.customer.value) where.customer = formRef.current.customer.value
    if (formRef.current.driver.value) where.driver = formRef.current.driver.value

    if (formRef.current.punched.checked) orderStatus.push("punched")
    if (formRef.current.assigned.checked) orderStatus.push("assigned")
    if (formRef.current.order_requested.checked) orderStatus.push("requested")
    if (formRef.current.packed.checked) orderStatus.push("packed")
    if (formRef.current.onway.checked) orderStatus.push("onway")
    if (formRef.current.delivered.checked) orderStatus.push("delivered")
    if (orderStatus.length > 0) where.order_status = orderStatus.join(",")

    if (formRef.current.unpaid.checked) paymentStatus.push("unpaid")
    if (formRef.current.paid.checked) paymentStatus.push("paid")
    if (formRef.current.payment_requested.checked) paymentStatus.push("requested")
    if (paymentStatus.length > 0) where.payment_status = paymentStatus.join(",")

    const updatedFilters = Object.keys(where).length > 0 ? { ...filters, offset: 0, ...where } : { ...filters }
    if (Object.keys(where).length === 0) {
      delete updatedFilters.payment_status;
      delete updatedFilters.order_status;
    }
    setFilters(updatedFilters)
  } 

  const handleReset = () => {
    setFilters({ limit: 10, offset: 0 })
  }

  const handleEdit = (id) => {
    navigate(`/dashboard/edit_order?id=${id}`)
  }

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete?")) return

      const response = await orderApis.trashOrder(token, id)
      if (!response.status) throw new Error(response.message)

      setOrders((previous) => {
        const orders = previous.filter(x => x.id !== id)
        return [...orders]
      })
    } catch (error) {
      alert(error.message)
    }
  }

  const handleAll = (e) => {
    if (e.target.checked)
      setSelected(orders.map(order => order.id))
    else
      setSelected([])
  }

  const handleChange = (id) => {
    if (!selected.includes(id))
      setSelected((previous) => {
        previous.push(id)
        return [...previous]
      })
    else
      setSelected((previous) => {
        return previous.filter(x => x !== id)
      })
  }

  const handlePay = async () => {
    try {
      if (!window.confirm("Are you sure you want to mark as paid?")) return

      const response = await orderApis.payOrder(token, selected.join(","))
      if (!response.status) throw new Error(response.message)

      setOrders((previous) => {
        const orders = previous.map(x => selected.includes(x.id) ? { ...x, payment_status: "paid" } : x)
        return [...orders]
      })
      setRows((previous) => {
        const orders = previous.map(x => selected.includes(x.id) ? { ...x, payment_status: "paid" } : x)
        return [...orders]
      })
      setSelected([])
    } catch (error) {
      alert(error.message)
    }
  }

  const handleRecover = async () => {
    try {
      if (!window.confirm("Are you sure you want to recover?")) return

      const response = await orderApis.recoverOrder(token, selected.join(","))
      if (!response.status) throw new Error(response.message)

      setOrders((previous) => {
        const orders = previous.map(x => selected.includes(x.id) ? { ...x, driver_id: null, driver: null, order_status: "punched" } : x)
        return [...orders]
      })
      setRows((previous) => {
        const orders = previous.map(x => selected.includes(x.id) ? { ...x, driver_id: null, driver: null, order_status: "punched" } : x)
        return [...orders]
      })
      setSelected([])
    } catch (error) {
      alert(error.message)
    }
  }

  const handleUnprocessed = async () => {
    try {
      if (!window.confirm("Are you sure you want to unprocessed?")) return

      const response = await dispatcherServices.orderUnprocessed(token, selected.map((id) => ({ order_id: id })))
      if (!response.status) throw new Error(response.message)

      setOrders((previous) => {
        const orders = previous.map(x => selected.includes(x.id) ? { ...x, processed: false } : x)
        return [...orders]
      })
      setRows((previous) => {
        const orders = previous.map(x => selected.includes(x.id) ? { ...x, processed: false } : x)
        return [...orders]
      })
      setSelected([])
    } catch (error) {
      alert(error.message)
    }
  }

  const handlePageChange = (page, offset) => {
    setFilters({ ...filters, offset })
    setActive(page)
  }

  const getOrders = async () => {
    try {
      const query = new URLSearchParams(filters)
      const response = await orderApis.getOrders(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setOrders(response.orders)
      setCount(response.count)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getOrders()
  }, [])

  useEffect(() => {
    getOrders(filters)
  }, [filters])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="orders" className="my-2">
        <form ref={formRef}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h3 className="fw-bold m-0">Orders</h3>
              <div className="btn-group btn-group-sm ms-2" role="group">
                {(permissions["PAY_ORDER"]) && (<button type="button" className="btn btn-sm btn-success" onClick={handlePay}>Mark as Paid</button>)}
                {(permissions["RECOVER_ORDER"]) && (<button type="button" className="btn btn-sm btn-danger" onClick={handleRecover}>Recover</button>)}
                <button type="button" className="btn btn-sm btn-danger" onClick={handleUnprocessed}>Unprocessed</button>
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-md-3 pe-1">
                <div className="d-flex align-items-center">
                  <label className="col-form-label">From</label>
                  <div className="input-group input-group-sm ms-1">
                    <input type="date" className="form-control bg-light" name="from_date" />
                    <input type="time" className="form-control bg-light" name="from_time" />
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-1">
                <div className="d-flex align-items-center">
                  <label className="col-form-label">To</label>
                  <div className="input-group input-group-sm ms-1">
                    <input type="date" className="form-control bg-light" name="to_date" />
                    <input type="time" className="form-control bg-light" name="to_time" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 ps-1 text-end">
                <button type="button" className="btn btn-sm btn-light text-primary me-2" onClick={handleReset} title="Refresh Orders"><i className="fas fa-sync-alt"></i></button>
                <button type="button" className="btn btn-sm btn-light text-primary me-2" onClick={handleSearch}><i className="fas fa-search"></i></button>
                <button type="reset" className="btn btn-sm btn-light text-danger" onClick={handleReset}><i className="fas fa-times"></i></button>
                <div className="btn-group btn-group-sm ms-2" role="group">
                  <input type="checkbox" className="btn-check" name="punched" id="punched" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-light" htmlFor="punched">Punched</label>
                  <input type="checkbox" className="btn-check" name="assigned" id="assigned" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-light" htmlFor="assigned">Assigned</label>
                  <input type="checkbox" className="btn-check" name="order_requested" id="order-requested" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-sm btn-light" htmlFor="order-requested">Requested</label>
                  <input type="checkbox" className="btn-check" name="packed" id="packed" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-light" htmlFor="packed">Packed</label>
                  <input type="checkbox" className="btn-check" name="onway" id="onway" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-light" htmlFor="onway">Onway</label>
                  <input type="checkbox" className="btn-check" name="delivered" id="delivered" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-light" htmlFor="delivered">Delivered</label>
                </div>
                <div className="btn-group btn-group-sm ms-2" role="group">
                  <input type="checkbox" className="btn-check" name="unpaid" id="unpaid" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-sm btn-light" htmlFor="unpaid">Unpaid</label>
                  <input type="checkbox" className="btn-check" name="payment_requested" id="payment-requested" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-sm btn-light" htmlFor="payment-requested">Requested</label>
                  <input type="checkbox" className="btn-check" name="paid" id="paid" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-sm btn-light" htmlFor="paid">Paid</label>
                </div>
              </div>
            </div>
          </div>

          <table className="table table-bordered table-hover shadow-none text-dark">
            <thead className="table-light">
              <tr>
                <th scope="col" className="align-middle text-center">
                  <input className="form-check-input" type="checkbox" checked={selected.length > 0} id="all" value="all" onChange={handleAll} />
                  <span className="ms-1">({selected.length})</span>
                </th>
                <th scope="col" style={{ width: "7%" }} className="align-middle">Order #</th>
                <th scope="col" style={{ width: "8%" }} className="align-middle">Date</th>
                <th scope="col" style={{ width: "15%" }} className="align-middle">Customer</th>
                <th scope="col" style={{ width: "7%" }} className="align-middle">Driver</th>
                <th scope="col" style={{ width: "23%" }} className="align-middle">Items</th>
                <th scope="col" style={{ width: "7%" }} className="align-middle text-center">Discount</th>
                <th scope="col" style={{ width: "7%" }} className="align-middle text-center">Total</th>
                <th scope="col" style={{ width: "15%" }} className="align-middle text-center">Tags</th>
                <th scope="col" style={{ width: "7%" }} className="align-middle text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="align-middle text-center p-1"></td>
                <td className="align-middle p-1"><input className="form-control form-control-sm" type="input" name="id" placeholder="ORDER #" /></td>
                <td className="align-middle p-1"></td>
                <td className="align-middle p-1"><input className="form-control form-control-sm" type="input" name="customer" placeholder="CUSTOMER" /></td>
                <td className="align-middle p-1"><input className="form-control form-control-sm" type="input" name="driver" placeholder="DRIVER" /></td>
                <td className="align-middle text-center p-1"></td>
                <td className="align-middle text-center p-1"></td>
                <td className="align-middle text-center p-1"></td>
                <td className="align-middle text-center p-1"></td>
                <td className="align-middle text-center p-1">
                  <button type="button" className="btn btn-sm btn-primary w-100" onClick={handleSearch}><i className="fas fa-search"></i></button>
                </td>
              </tr>
              {orders.map((row) => {
                return (
                  <tr key={row.id.toString()} onDoubleClick={() => handleView(row.id)}>
                    <td className="align-middle text-center">
                      <input className="form-check-input" type="checkbox" id={row.id} value={row.id} onChange={() => handleChange(row.id)} checked={selected.includes(row.id)} />
                    </td>
                    <td className="align-middle">P{row.id}</td>
                    <td className="align-middle">{moment(row.order_datetime).format("DD-MM-YYYY")}</td>
                    <td className="align-middle">{row.customer.mobile} {row.customer.name ? ` | ${row.customer.name}` : ''}<br /><span className="small"><i className="text-danger bi bi-geo-alt me-1"></i>{row.customer.address}</span></td>
                    <td className="align-middle">{row.driver?.name}</td>
                    <td className="align-middle small">{row.order_lineitems.map((item) => {
                      if (isDeal(item.type))
                        return <div key={`deal-${item.id.toString()}`}>{item.title} x{item.quantity} = PKR {(item.quantity * (item.sale + item.products.reduce((previous, current) => previous += current.swap || 0, 0))).toFixed(2)}</div>
                      else
                        return <div key={`product-${item.id.toString()}`}>{item.title} x{item.quantity} = PKR {(item.quantity * item.price).toFixed(2)}</div>
                    })}</td>
                    <td className="align-middle text-center">Rs. {row.discount.toFixed(2)}</td>
                    <td className="align-middle text-center">Rs. {(row.total - row.discount).toFixed(2)}</td>
                    <td className="align-middle text-center">{getProcessedStatus[row.processed]} {getOrderStatus[row.order_status]} {getPaymentStatus[row.payment_status]}</td>
                    <td className="align-middle text-center">
                      {(permissions["UPDATE_ORDER"]) && (<a className="me-2" onClick={() => handleEdit(row.id)}><i className="far fa-edit"></i></a>)}
                      {(permissions["READ_ORDER"]) && (<a className="me-2" onClick={() => handleView(row.id)}><i className="far fa-clipboard"></i></a>)}
                      {(permissions["TRASH_ORDER"]) && (<a className="text-danger" onClick={() => handleDelete(row.id)}><i className="far fa-trash-alt"></i></a>)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <div className="container-fluid">
            <nav aria-label="pagination">
              <Pagination
                currentPage={active}
                totalCount={count}
                pageSize={filters.limit}
                offset={filters.offset}
                siblingCount={3}
                onPageChange={handlePageChange}
              />
            </nav>
          </div>
        </form>
      </main>
    </Layout>
  )
}

export default Orders
