import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as userApis from "../../utilities/apis/user"
import * as permissionsApis from "../../utilities/apis/permissions"
import Layout from "../../components/Layout"

const User = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const [roles, setRoles] = useState([])
  const nameRef = useRef(null)
  const usernameRef = useRef(null)
  const passwordRef = useRef(null)
  const roleRef = useRef(null)
  const params = new URLSearchParams(window.location.search)

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const name = e.target.name.value
      const username = e.target.username.value
      const password = e.target.password.value
      const role_id = e.target.role.value
      const payload = { name, username, password, role_id }

      if (!params.has("id")) {
        const response = await userApis.createUser(token, payload)
        if (!response.status) throw new Error(response.message)

        nameRef.current.value = ""
        usernameRef.current.value = ""
        passwordRef.current.value = ""
        roleRef.current.value = ""
      } else {
        payload.id = parseInt(params.get("id"))
        const response = await userApis.updateUser(token, payload)
        if (!response.status) throw new Error(response.message)
      }

      alert("User saved")
      navigate("/dashboard/users")
    } catch (error) {
      alert(error.message)
    }
  }

  const getUser = async () => {
    try {
      const response = await userApis.getUser(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      nameRef.current.value = response.user.name
      usernameRef.current.value = response.user.username
      passwordRef.current.value = response.user.password
      setTimeout(() => {
        roleRef.current.value = response.user.role_id
      }, 10)

    } catch (error) {
      alert(error.message)
    }
  }

  const getRoles = async () => {
    try {
      const response = await permissionsApis.getRoles(token)
      if (!response.status) throw new Error(response.message)

      setRoles(response.roles)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getRoles()

    if (params.has("id")) getUser()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="driver" className="my-4">
        <div className="container">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <div className="card">
                <div className="card-header bg-white border-bottom-0 text-center">
                  <h3 className="fw-bold m-0">User</h3>
                </div>
                <div className="card-body">
                  <form method="POST" onSubmit={handleSubmit}>
                    <div className="form-floating mb-3">
                      <input ref={nameRef} type="text" className="form-control" id="name" placeholder="Name" required />
                      <label htmlFor="name">Name</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={usernameRef} type="text" className="form-control" id="username" placeholder="Username" required />
                      <label htmlFor="username">Username</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={passwordRef} type="password" className="form-control" id="password" placeholder="Password" required />
                      <label htmlFor="password">Password</label>
                    </div>
                    <div className="form-floating mb-3">
                      <select ref={roleRef} className="form-select" id="role" required>
                        {roles.map((role) => (
                          <option key={role.id.toString()} value={role.id}>{role.name.charAt(0).toUpperCase() + role.name.slice(1)}</option>
                        ))}
                      </select>
                      <label htmlFor="role">Select Role</label>
                    </div>
                    <div className="d-grid">
                      <button type="submit" className="btn btn-primary py-3">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-4"></div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default User
