import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as requestApis from "../../utilities/apis/order_request"
import { getOrderRequestType, getRequestStatus } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"
import Pagination from "../../components/Pagination"

const OrderRequests = () => {
  const navigate = useNavigate()
  const { token, user, permissions } = useSelector(state => state.user)
  const formRef = useRef(null)
  const [orderRequests, setOrderRequests] = useState([])
  const [rows, setRows] = useState([])
  const [count, setCount] = useState(0)
  const [selected, setSelected] = useState([])
  const [active, setActive] = useState(1)
  const limit = 10
  const offset = 0

  const handleView = (id) => {
    navigate(`/dashboard/order_request?id=${id}`)
  }

  const handleSearch = () => {
    const where = {}
    const type = []
    const status = []

    if (formRef.current.from_date.value && formRef.current.from_time.value && formRef.current.to_date.value && formRef.current.to_time.value) {
      where.from_datetime = moment(`${formRef.current.from_date.value} ${formRef.current.from_time.value}`).format("YYYY-MM-DD HH:mm")
      where.to_datetime = moment(`${formRef.current.to_date.value} ${formRef.current.to_time.value}`).format("YYYY-MM-DD HH:mm")
    } else {
      if (formRef.current.from_date.value && formRef.current.to_date.value) {
        where.from_date = moment(formRef.current.from_date.value).format("YYYY-MM-DD")
        where.to_date = moment(formRef.current.to_date.value).format("YYYY-MM-DD")
      }
    }

    if (formRef.current.reroute.checked) type.push("reroute")
    if (formRef.current.cancel.checked) type.push("cancel")
    if (type.length > 0) where.type = type.join(",")

    if (formRef.current.requested.checked) status.push("requested")
    if (formRef.current.completed.checked) status.push("completed")
    if (formRef.current.rejected.checked) status.push("rejected")
    if (status.length > 0) where.status = status.join(",")

    getRequestOrders(where)
  }

  const handleReset = () => {
    getRequestOrders()
  }

  const handleAll = (e) => {
    if (e.target.checked)
      setSelected(orderRequests.filter(order => order.status === "requested").map(order => ({ id: order.id, order_id: order.order_id })))
    else
      setSelected([])
  }

  const handleChange = (id, order_id) => {
    if (!selected.find(x => x.id === id && x.order_id === order_id))
      setSelected((previous) => {
        previous.push({ id, order_id })
        return [...previous]
      })
    else
      setSelected((previous) => {
        return previous.filter(x => x.id !== id && x.order_id !== order_id)
      })
  }

  const handleComplete = async () => {
    try {
      if (!selected.length) throw new Error("Please select at least one request")

      const ids = selected.map(x => x.id)
      const orderIds = selected.map(x => x.order_id)
      const payload = {
        ids, order_ids: orderIds
      }
      const response = await requestApis.completeOrderRequests(token, payload)
      if (!response.status) throw new Error(response.message)

      setOrderRequests((prevRequests) => {
        return prevRequests.map((request) => {
          if (ids.includes(request.id)) {
            return {
              ...request,
              status: "completed"
            }
          }
          return request
        })
      })

      alert("Selected requests have been marked as completed")
      getRequestOrders()
    } catch (error) {
      alert(error.message)
    }
  }

  const handleReject = async () => {
    try {
      if (!selected.length) throw new Error("Please select at least one request")

      const ids = selected.map(x => x.id)
      const orderIds = selected.map(x => x.order_id)
      const payload = {
        ids, order_ids: orderIds
      }
      const response = await requestApis.rejectOrderRequests(token, payload)
      if (!response.status) throw new Error(response.message)

      setOrderRequests((prevRequests) => {
        return prevRequests.map((request) => {
          if (ids.includes(request.id)) {
            return {
              ...request,
              status: "rejected"
            }
          }
          return request
        })
      })

      alert("Selected requests have been marked as rejected")
      getRequestOrders()
    } catch (error) {
      alert(error.message)
    }
  }

  const handlePageChange = (page, offset) => {
    getRequestOrders({ limit, offset })
    setActive(page)
  }

  const getRequestOrders = async (params = {}) => {
    try {
      const query = new URLSearchParams({limit, offset, ...params})

      const response = await requestApis.getOrderRequests(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setOrderRequests(response.order_requests)
      setCount(response.count)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    handleSearch()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="orders" className="my-2">
        <form ref={formRef}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h3 className="fw-bold m-0">Order Requests</h3>
              <div className="d-flex gap-2">
                {(permissions["COMPLETE_ORDER_REQUESTS"]) && (<button type="button" className="btn btn-sm btn-success" onClick={handleComplete}>Complete Requests</button>)}
                {(permissions["REJECT_ORDER_REQUESTS"]) && (<button type="button" className="btn btn-sm btn-danger" onClick={handleReject}>Reject Requests</button>)}
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-md-3 pe-1">
                <div className="d-flex align-items-center">
                  <label className="col-form-label">From</label>
                  <div className="input-group input-group-sm ms-1">
                    <input type="date" className="form-control bg-light" name="from_date" />
                    <input type="time" className="form-control bg-light" name="from_time" />
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-1">
                <div className="d-flex align-items-center">
                  <label className="col-form-label">To</label>
                  <div className="input-group input-group-sm ms-1">
                    <input type="date" className="form-control bg-light" name="to_date" />
                    <input type="time" className="form-control bg-light" name="to_time" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 ps-1 text-end">
                <div className="btn-group btn-group-sm" role="group">
                  <button type="button" className="btn btn-sm btn-light text-primary" onClick={handleSearch}><i className="fas fa-search"></i></button>
                  <button type="reset" className="btn btn-sm btn-light text-danger" onClick={handleReset}><i className="fas fa-times"></i></button>
                </div>
                <div className="btn-group btn-group-sm ms-2" role="group">
                  <input type="checkbox" className="btn-check" name="reroute" id="reroute" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-sm btn-light" htmlFor="reroute">Reroute</label>
                  <input type="checkbox" className="btn-check" name="cancel" id="cancel" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-sm btn-light" htmlFor="cancel">Cancel</label>
                </div>
                <div className="btn-group btn-group-sm ms-2" role="group">
                  <input type="checkbox" className="btn-check" name="requested" id="requested" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-sm btn-light" htmlFor="requested">Requested</label>
                  <input type="checkbox" className="btn-check" name="completed" id="completed" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-sm btn-light" htmlFor="completed">Completed</label>
                  <input type="checkbox" className="btn-check" name="rejected" id="rejected" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-sm btn-light" htmlFor="rejected">Rejected</label>
                </div>
              </div>
            </div>
          </div>

          <table className="table table-bordered table-hover shadow-none text-dark">
            <thead className="table-light">
              <tr>
                <th scope="col" className="align-middle text-center">
                  <input className="form-check-input" type="checkbox" id="all" value="all" onChange={handleAll} />
                  <span className="ms-1">({selected.length})</span>
                </th>
                <th scope="col" style={{ width: "9%" }} className="align-middle">Req #</th>
                <th scope="col" style={{ width: "9%" }} className="align-middle">Order #</th>
                <th scope="col" style={{ width: "12%" }} className="align-middle">Type</th>
                <th scope="col" style={{ width: "12%" }} className="align-middle">Date</th>
                <th scope="col" style={{ width: "13%" }} className="align-middle">Rider</th>
                <th scope="col" style={{ width: "18%" }} className="align-middle">Note</th>
                <th scope="col" style={{ width: "12%" }} className="align-middle text-center">Status</th>
                <th scope="col" style={{ width: "10%" }} className="align-middle text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {orderRequests.map((row) => {
                return (
                  <tr key={row.id.toString()} onDoubleClick={() => handleView(row.id)}>
                    <td className="align-middle text-center">
                      {row.status === "requested" && <input className="form-check-input" type="checkbox" id={row.id} value={row.id} onChange={() => handleChange(row.id, row.order_id)} checked={selected.find(x => x.id === row.id && x.order_id === row.order_id)} />}
                    </td>
                    <td className="align-middle">PREQ{row.id}</td>
                    <td className="align-middle"><a href={`order?id=${row.order_id}`}>P{row.order_id}</a></td>
                    <td className="align-middle">{getOrderRequestType[row.type]}</td>
                    <td className="align-middle">{moment(row.order_datetime).format("DD-MM-YYYY")}</td>
                    <td className="align-middle">{row.driver?.name}</td>
                    <td className="align-middle">{row.note}</td>
                    <td className="align-middle text-center">{getRequestStatus[row.status]}</td>
                    <td className="align-middle text-center">
                      {(permissions["READ_ORDER_REQUEST"]) && (<a className="me-2" onClick={() => handleView(row.id)}><i className="far fa-clipboard"></i></a>)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <div className="container-fluid">
            <nav aria-label="pagination">
              <Pagination
                currentPage={active}
                totalCount={count}
                pageSize={limit}
                offset={offset}
                siblingCount={3}
                onPageChange={handlePageChange}
              />
            </nav>
          </div>
        </form>
      </main>
    </Layout>
  )
}

export default OrderRequests
