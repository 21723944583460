import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as productApis from "../../utilities/apis/product"
import * as categoryApis from "../../utilities/apis/category"
import Layout from "../../components/Layout"
import { url } from "../../utilities/enumerations/constants" 

const Product = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const thumbnailRef = useRef(null)
  const thumbnailFileRef = useRef(null)
  const thumbnail64Ref = useRef(null)
  const image1Ref = useRef(null)
  const image1FileRef = useRef(null)
  const image164Ref = useRef(null)
  const titleRef = useRef(null)
  const slugRef = useRef(null)
  const descriptionRef = useRef(null)
  const categoryRef = useRef(null)
  const quantityRef = useRef(null)
  const costRef = useRef(null)
  const priceRef = useRef(null)
  const videoRef = useRef(null)
  const whatsappRef = useRef(null)
  const params = new URLSearchParams(window.location.search)
  const [categories, setCategories] = useState([])
  const [homePage, setHomePage] = useState(false)

  const setImage = (type, base64) => {
    switch (type) {
      case "thumbnail":
        thumbnailRef.current.src = base64
        thumbnail64Ref.current.value = base64
        break
      case "image1":
        image1Ref.current.src = base64
        image164Ref.current.value = base64
        break
    }
  }

  const handleChange = async (e) => {
    try {
      if (e.target.files && e.target.files[0]) {
        if (e.target.files[0].size > 1024000) throw new Error("Image size must be maximum of 1MB")
        if (!["image/jpeg", "image/jpg", "image/png"].includes(e.target.files[0].type)) throw new Error("image must be in format of .jpg, .jpeg or .png")

        const reader = new FileReader()
        const type = e.target.id

        reader.addEventListener("load", function (e) {
          setImage(type, e.target.result)
        })

        reader.readAsDataURL(e.target.files[0])
      }
    } catch (error) {
      alert(error.message)
    }
  }

  const handleClear = (type) => {
    switch (type) {
      case "thumbnail":
        thumbnailRef.current.src = "/upload.jpg"
        thumbnailFileRef.current.value = ""
        thumbnail64Ref.current.value = ""
        break
      case "image1":
        image1Ref.current.src = "/upload.jpg"
        image1FileRef.current.value = ""
        image164Ref.current.value = ""
        break
    }
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const form = new FormData()

      if (e.target.thumbnail.files[0])
        form.append("thumbnail_url", e.target.thumbnail.files[0])
      else
        form.append("thumbnail_url", thumbnail64Ref.current.value)

      if (e.target.image1.files[0])
        form.append("image_url", e.target.image1.files[0])
      else
        form.append("image_url", image164Ref.current.value)
    
      form.append("title", titleRef.current.value)
      form.append("slug", slugRef.current ? slugRef.current.value : '')
      form.append("description", descriptionRef.current.value)
      form.append("cost", costRef.current.value)
      form.append("price", priceRef.current.value)
      form.append("quantity", quantityRef.current.value)
      form.append("category_id", categoryRef.current.value)
      form.append("whatsapp_url", whatsappRef.current ? whatsappRef.current.value : '')
      form.append("video_url", videoRef.current ? videoRef.current.value : '')
      form.append("home_page", homePage)

      if (!params.has("id")) {
        const response = await productApis.createProduct(token, form)
        if (!response.status) throw new Error(response.message)

        titleRef.current.value = ""
        descriptionRef.current.value = ""
        slugRef.current.value = ""
        costRef.current.value = ""
        priceRef.current.value = ""
        quantityRef.current.value = ""
        categoryRef.current.value = categories[0].id
        whatsappRef.current.value = ""
        videoRef.current.value = ""
        handleClear("thumbnail")
        handleClear("thumbnail_url")
        handleClear("image_url")
        handleClear("image1")
      } else {
        form.append("id", parseInt(params.get("id")))
        const response = await productApis.updateProduct(token, form)
        if (!response.status) throw new Error(response.message)
      }

      alert("Product saved")
    } catch (error) {
      alert(error.message)
    }
  }

  const getProduct = async () => {
    try {
      const response = await productApis.getProduct(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      setHomePage(response.product.home_page)
      titleRef.current.value = response.product.title
      slugRef.current.value = response.product.slug
      descriptionRef.current.value = response.product.description
      costRef.current.value = response.product.cost
      priceRef.current.value = response.product.price
      quantityRef.current.value = response.product.quantity
      categoryRef.current.value = response.product.category_id
      slugRef.current.value = response.product.slug

      thumbnailRef.current.src = response.product.thumbnail_url?.replace("public", url)
      thumbnail64Ref.current.value = response.product.thumbnail_url
      
      image1Ref.current.src = response.product.image1 || response.product.image_url?.replace("public", url) || "/upload.jpg"
      image164Ref.current.value = response.product.image_url
    } catch (error) {
      alert(error.message)
    }
  }

  const getCategories = async () => {
    try {
      const response = await categoryApis.getCategories(token, "")
      if (!response.status) throw new Error(response.message)

      setCategories(response.categories)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getCategories()
    if (params.has("id"))
      getProduct()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="product" className="my-4">
        <div className="container">
          <form method="POST" onSubmit={handleSubmit}>
            <div className="card">
              <div className="card-header bg-white border-bottom-0 text-center">
                <h3 className="fw-bold m-0">Product</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-2">
                    <div className="card position-relative">
                      <a className="reset text-danger" onClick={() => handleClear("thumbnail")}><i className="fas fa-times"></i></a>
                      <div className="card-body p-0">
                        <input ref={thumbnail64Ref} type="hidden" />
                        <input ref={thumbnailFileRef} type="file" id="thumbnail" className="form-control d-none" accept=".jpg, .jpeg, .png" onChange={handleChange} />
                        <img ref={thumbnailRef} src="/upload.jpg" className="rounded" onClick={() => thumbnailFileRef.current.click()} />
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="card position-relative">
                      <a className="reset text-danger" onClick={() => handleClear("image1")}><i className="fas fa-times"></i></a>
                      <div className="card-body p-0">
                        <input ref={image164Ref} type="hidden" />
                        <input ref={image1FileRef} type="file" id="image1" className="form-control d-none" accept=".jpg, .jpeg, .png" onChange={handleChange} />
                        <img ref={image1Ref} src="/upload.jpg" className="rounded" onClick={() => image1FileRef.current.click()} />
                      </div>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="home-page" checked={homePage} onChange={() => setHomePage(!homePage)} />
                      <label className="form-check-label" htmlFor="home-page">Display on Home Page</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={titleRef} type="text" className="form-control" id="title" placeholder="Title" required />
                      <label htmlFor="title">Title</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={slugRef} type="text" className="form-control" id="slug" placeholder="Slug" required />
                      <label htmlFor="slug">Slug</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={descriptionRef} type="text" className="form-control" id="description" placeholder="Description" required />
                      <label htmlFor="description">Description</label>
                    </div>
                    <div className="row g-3">
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <select ref={categoryRef} className="form-select" id="category" required>
                            {categories.map((category) => (
                              <option key={category.id.toString()} value={category.id}>{category.name}</option>
                            ))}
                          </select>
                          <label htmlFor="category">Select Category</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input ref={costRef} type="number" className="form-control" id="cost" placeholder="Cost" />
                          <label htmlFor="cost">Cost</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input ref={whatsappRef} type="text" className="form-control" id="whatsapp" placeholder="Whatsapp Url" />
                          <label htmlFor="whatsapp_url">Whatsapp Url</label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input ref={quantityRef} type="number" className="form-control" id="quantity" placeholder="Quantity" required />
                          <label htmlFor="quantity">Quantity</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input ref={priceRef} type="number" className="form-control" id="price" placeholder="Price" required />
                          <label htmlFor="price">Price</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input ref={videoRef} type="text" className="form-control" id="video" placeholder="Video Url" />
                          <label htmlFor="video">Video Url</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button type="submit" className="btn btn-primary py-3 w-25">Save</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </Layout>
  )
}

export default Product