import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as cashFlowInApis from "../../utilities/apis/cashFlowIn"
import * as driverApis from "../../utilities/apis/driver"
import Layout from "../../components/Layout"

const CashFlowIn = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const commentRef = useRef(null)
  const totalRef = useRef(null)
  const cashRef = useRef(null)
  const onlineRef = useRef(null)
  const deductionRef = useRef(null)
  const entryDateRef = useRef(null)
  const driverRef = useRef(null)
  const params = new URLSearchParams(window.location.search)
  const [drivers, setDrivers] = useState([])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const comment = e.target.comment.value
      const total = e.target.total.value || null
      const cash = e.target.cash.value
      const online = e.target.online.value
      const deduction = e.target.deduction.value
      const entryDate = e.target.entryDate.value || null
      const driver = e.target.driver.value || null
      const payload = { comment, total, cash, online, deduction, entry_date: entryDate, driver_id: driver }

      if (!params.has("id")) {
        const response = await cashFlowInApis.createCashFlowIn(token, payload)
        if (!response.status) throw new Error(response.message)

        commentRef.current.value = ""
        totalRef.current.value = ""
        cashRef.current.value = ""
        onlineRef.current.value = ""
        entryDateRef.current.value = ""
        driverRef.current.value = drivers[0].id.toString()
      } else {
        payload.id = parseInt(params.get("id"))
        const response = await cashFlowInApis.updateCashFlowIn(token, payload)
        if (!response.status) throw new Error(response.message)
      }

      alert("Cash Flow In saved")
    } catch (error) {
      alert(error.message)
    }
  }

  const getCashFlowIn = async () => {
    try {
      const response = await cashFlowInApis.getCashFlowIn(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      if (response.cash_flow_out.comment) commentRef.current.value = response.cash_flow_out.comment
      totalRef.current.value = response.cash_flow_out.total
      cashRef.current.value = response.cash_flow_out.cash
      onlineRef.current.value = response.cash_flow_out.online
      entryDateRef.current.value = response.cash_flow_out.entryDate
    } catch (error) {
      alert(error.message)
    }
  }

  const getDrivers = async () => {
    try {
      const response = await driverApis.getDrivers(token, "")
      if (!response.status) throw new Error(response.message)

      setDrivers(response.drivers)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getDrivers()
    if (params.has("id"))
      getCashFlowIn()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customer" className="my-4">
        <div className="container">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <div className="card">
                <div className="card-header bg-white border-bottom-0 text-center">
                  <h3 className="fw-bold m-0">Cash Flow In</h3>
                </div>
                <div className="card-body">
                  <form method="POST" onSubmit={handleSubmit}>
                    <div className="form-floating mb-3">
                      <input ref={commentRef} type="text" className="form-control" id="comment" placeholder="Comment" />
                      <label htmlFor="comment">Comment</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={totalRef} type="number" className="form-control" id="total" placeholder="Total" step="0.01" required />
                      <label htmlFor="total">Total</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={cashRef} type="number" className="form-control" id="cash" placeholder="Cash" step="0.01" required />
                      <label htmlFor="cash">Cash</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={onlineRef} type="number" className="form-control" id="online" placeholder="Online" step="0.01" required />
                      <label htmlFor="online">Online</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={deductionRef} type="number" className="form-control" id="deduction" placeholder="Deduction" step="0.01" required />
                      <label htmlFor="deduction">Deduction</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={entryDateRef} type="datetime-local" className="form-control" id="entryDate" placeholder="Entry Date" required />
                      <label htmlFor="entry-date">Entry Date</label>
                    </div>
                    <div className="form-floating mb-3">
                      <select ref={driverRef} className="form-select" id="driver">
                        {drivers.map((driver) => (
                          <option key={driver.id.toString()} value={driver.id}>{driver.name}</option>
                        ))}
                      </select>
                      <label htmlFor="zone">Select Driver</label>
                    </div>
                    <div className="d-grid">
                      <button type="submit" className="btn btn-primary py-3">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-4"></div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default CashFlowIn
