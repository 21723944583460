import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as cashFlowOutApis from "../../utilities/apis/cashFlowOut"
import Layout from "../../components/Layout"
import Pagination from "../../components/Pagination"

const CashFlowOuts = () => {
  const navigate = useNavigate()
  const { token, user, permissions } = useSelector(state => state.user)
  const [cashFlowOuts, setCashFlowOuts] = useState([])
  const [active, setActive] = useState(1)
  const [count, setCount] = useState(0)
  const mobileRef = useRef(null)
  const [filters, setFilters] = useState({
    offset: 0,
    limit: 10,
  })

  const handleNew = () => {
    navigate("/dashboard/cash-flow-out")
  }

  const handleEdit = (id) => {
    navigate(`/dashboard/cash-flow-out?id=${id}`)
  }

  const handleSearch = () => {
    const where = {}

    if (mobileRef.current.value) where.mobile = mobileRef.current.value

    setFilters({ ...filters, offset: 0, ...where })
    setActive(1)
  }

  const handleReset = () => {
    setFilters({ limit: 10, offset: 0 })
    mobileRef.current.value = ''
    setActive(1)
  }

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete?")) return

      const response = await cashFlowOutApis.deleteCashFlowOut(token, id)
      if (!response.status) throw new Error(response.message)

      setCashFlowOuts((previous) => {
        const cashFlowOuts = previous.filter(x => x.id !== id)
        return [...cashFlowOuts]
      })
    } catch (error) {
      alert(error.message)
    }
  }

  const getCashFlowOuts = async () => {
    try {
      const query = new URLSearchParams(filters)
      const response = await cashFlowOutApis.getCashFlowOuts(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setCount(response.count)
      setCashFlowOuts(response.cash_flow_outs)
    } catch (error) {
      alert(error.message)
    }
  }

  const handlePageChange = (page, offset) => {
    setFilters({ ...filters, offset })
    setActive(page)
  }

  useEffect(() => {
    if (!user) navigate("/")

    getCashFlowOuts()
  }, [])

  useEffect(() => {
    getCashFlowOuts(filters)
  }, [filters])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customers" className="my-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h3 className="fw-bold m-0">Cash Flow Outs</h3>
              </div>
            </div>
            <div className="col-3" />
            <div className="d-flex mb-3 col-3 gap-2">
              <input ref={mobileRef} type="text" className="form-control" id="mobile" placeholder="Mobile #" required autoFocus />
              <button type="button" className="btn btn-sm btn-light text-primary" onClick={handleSearch}><i className="fas fa-search"></i></button>
              <button type="reset" className="btn btn-sm btn-light text-danger" onClick={handleReset}><i className="fas fa-times"></i></button>
              {(permissions["CREATE_CASH_FLOW_OUT"]) && (<button className="btn btn-primary" onClick={() => handleNew()}>New</button>
              )}
            </div>
          </div>
        </div>
        <table className="table table-bordered table-hover shadow-none">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">ID</th>
              <th scope="col" style={{ width: "12%" }} className="align-middle">Type</th>
              <th scope="col" style={{ width: "30%" }} className="align-middle">Comment</th>
              <th scope="col" style={{ width: "12%" }} className="align-middle">Amount</th>
              <th scope="col" style={{ width: "12%" }} className="align-middle">Date</th>
              <th scope="col" style={{ width: "12%" }} className="align-middle">Entry Date</th>
              <th scope="col" style={{ width: "12%" }} className="align-middle text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {cashFlowOuts.map((row, index) => (
              <tr key={row.id.toString()}>
                <td className="align-middle text-center">{(index + 1) + ((active - 1) * filters.limit)}</td>
                <td className="align-middle">{row.type}</td>
                <td className="align-middle">{row.comment}</td>
                <td className="align-middle">Rs. {row.amount.toFixed(2)}</td>
                <td className="align-middle">{row.date && moment(row.date).format("DD-MM-YYYY")}</td>
                <td className="align-middle">{row.entry_date && moment(row.entry_date).format("DD-MM-YYYY")}</td>
                <td className="align-middle text-center">
                  {(permissions["UPDATE_CASH_FLOW_OUT"]) && (<a className="me-2" onClick={() => handleEdit(row.id)}><i className="far fa-edit"></i></a>)}
                  {(permissions["DELETE_CASH_FLOW_OUT"]) && (<a className="text-danger" onClick={() => handleDelete(row.id)}><i className="far fa-trash-alt"></i></a>)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="container-fluid">
          <nav aria-label="pagination">
            <Pagination
              currentPage={active}
              totalCount={count}
              pageSize={filters.limit}
              offset={filters.offset}
              siblingCount={3}
              onPageChange={handlePageChange}
            />
          </nav>
        </div>
      </main>
    </Layout>
  )
}

export default CashFlowOuts
